<template>
  <div>
    <Navgation bgColor="#060B4C" :isPageHome="true" />
    <div
      :class="{
        'option-cell2': true,
        fixed: true,
        'move-top': isShowXD,
        'move-hide': isShowXD2,
      }"
      v-show="isShowAdsorption"
    >
      <div class="form-cell">
        <div class="hm-cell" >
          <div class="form-item form-ml">
            <el-input
              v-model.trim="joinForm.city"
              placeholder="请输入您所在的城市"
              maxlength="11"
            ></el-input>
          </div>
          <div class="form-item form-ml">
            <el-input
              v-model.trim="joinForm.name"
              placeholder="请输入您的姓名"
              maxlength="11"
            ></el-input>
          </div>
          <div class="form-item form-ml form-mr">
            <el-input
             v-model.number="joinForm.mobile"
              placeholder="请输入您的手机号"
              maxlength="11"
              minlength="11"
            ></el-input>
          </div>
          <div class="submit-btn" @click="applyJoin">提交</div>
        </div>
      </div>
    </div>
    <div class="product-sjsyszc-container">
      <section class="banner">
        <div class="banner-content">
          <img
            class="phone-img"
            src="@/assets/images/product/yszc-phone.png"
            alt=""
          />
          <div class="banner-text">
            <div class="banner-text-title">免费注册公司，就用云上注册</div>
            <div class="banner-text-introduce">
              十角兽云上注册，足不出户，快速办证
            </div>
            <!-- <div class="banner-text-qr">
              <img
                class="qr-img"
                src="@/assets/images/product/yszc-qr.png"
                alt=""
              />
              <div class="qr-label">扫码体验</div>
            </div> -->
            <div class="join-form-box" >
              <div class="join-form">
                <el-form ref="joinFormRef" :model="joinForm" label-width="0px">
                  <el-form-item>
                    <el-input
                      v-model.trim="joinForm.city"
                      placeholder="请输入您所在的城市"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-input
                      v-model.trim="joinForm.name"
                      placeholder="请输入您的姓名"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-input
                      v-model.number="joinForm.mobile"
                      placeholder="请输入您的手机号"
                      maxlength="11"
                      minlength="11"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div class="apply-btn" @click="applyJoin">提交</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="sjsdzjqr-video">
        <!-- @click="videoClick" -->
        <div class="video-shadow"></div>
        <video
          ref="sjsdzjqrVideRef"
          src="/videos/yszc2.mp4"
          autoplay
          loop
          muted
        ></video>
        <!-- <div v-show="isShowMask" class="video-mask" @click="videoClick">
          <div
            class="play-btn"
            @click.stop="videoPlay"
            v-show="!videoPlayStatus"
          >
            <img
              class="play-img"
              src="@/assets/images/product/play.png"
              alt=""
            />
          </div>
          <div
            class="play-btn"
            @click.stop="videoPause"
            v-show="videoPlayStatus"
          >
            <img
              class="play-img"
              src="@/assets/images/product/pause.png"
              alt=""
            />
          </div>
        </div> -->
      </section>
      <section class="service">
        <div
          class="service-content"
          :class="{
            'pre-hide-100': true,
            'move-bottom-100': isShowService,
          }"
        >
          <div class="service-title section-title">了解我们的服务</div>
          <div class="service-label">
            全场景全链路的企业工商财税产品，助力企业发展
          </div>
          <div class="service-module">
            <div
              @mouseover="serviceHoverIndex = serviceModuleIndex"
              class="service-module-item"
              v-for="(serviceModuleItem, serviceModuleIndex) in serviceModules"
              :key="serviceModuleItem.title"
            >
              <!-- <div
                class="service-module-hover-item-content"
                v-if="serviceHoverIndex === serviceModuleIndex"
              >
                <div class="service-module-hover-item-content-line"></div>
                <div class="service-module-hover-item-content-text">
                  {{ serviceModuleItem.content }}
                </div>
              </div> -->
              <div class="service-module-item-content" >
                <img :src="serviceModuleItem.img" alt="" />
                <div class="service-module-item-content-title">
                  {{ serviceModuleItem.title }}
                </div>
                <div class="service-module-item-content-label">
                  {{ serviceModuleItem.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="gszc">
        <div
          class="gszc-content"
          :class="{
            'pre-hide-300': true,
            'move-bottom-300': isShowGszc,
          }"
        >
          <div class="gszc-title section-title">五步完成工商注册</div>
          <div class="gszc-steps">
            <!-- 起名 -->
            <div class="gszc-steps-item gszc-steps-qm">
              <div class="gszc-steps-item-left qm-text">
                <div class="gszc-steps-item-title">起名</div>
                <div class="gszc-steps-item-content">
                  输入地区、关键字、行业名称等信息，帮助用户起企业名称，根据大数据算法匹配，给予对应的推荐名称
                </div>
              </div>
              <div class="gszc-steps-item-right qm-img">
                <img src="@/assets/images/product/yszc-gszc-qm.png" alt="" />
                <div class="yszc-gszc-guide">
                  <img
                    src="@/assets/images/product/yszc-gszc-guide.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <!-- 测名 -->
            <div class="gszc-steps-item" style="margin-top: 104px">
              <div class="gszc-steps-item-left cm-img">
                <img src="@/assets/images/product/yszc-gszc-cm.png" alt="" />
              </div>
              <div class="gszc-steps-item-right cm-text">
                <div class="gszc-steps-item-title">测名</div>
                <div class="gszc-steps-item-content">
                  根据用户输入的企业名称关键字，根据算法规则，给予评分测评，同时根据关键字，进行诗词匹配，给予企业名称的相关注释
                </div>
              </div>
            </div>
            <!-- 核名 -->
            <div class="gszc-steps-item" style="margin-top: 36px">
              <div class="gszc-steps-item-left hm-text">
                <div class="gszc-steps-item-title">核名</div>
                <div class="gszc-steps-item-content">
                  <p>
                    <span class="medium-text">普通核名</span
                    ><span class="highlight-text">（免费）</span
                    >：根据用户输入的地区、字号、行业信息等信息，通过名称匹配，给予初步的企业名称注册通过率
                  </p>
                  <p style="margin-top: 20px">
                    <span class="medium-text">精准核名</span
                    >：根据用户输入的地区、字号、行业信息等信息，调取当地工商局，获取该名字的通过率，此结果为企业注册时企业名称精准的通过结果
                  </p>
                  <p style="margin-top: 20px">
                    <span class="medium-text"
                      >目前支持地区为：江苏、浙江、安徽、上海</span
                    >
                  </p>
                </div>
              </div>
              <div class="gszc-steps-item-right hm-img">
                <img src="@/assets/images/product/yszc-gszc-hm.png" alt="" />
              </div>
            </div>
            <!-- 经营范围 -->
            <div class="gszc-steps-item" style="margin-top: 80px">
              <div class="gszc-steps-item-left jyfw-img">
                <img src="@/assets/images/product/yszc-gszc-jyfw.png" alt="" />
              </div>
              <div class="gszc-steps-item-right jyfw-text">
                <div class="gszc-steps-item-title">经营范围</div>
                <div class="gszc-steps-item-content">
                  <span class="medium-text">经营范围查询工具</span
                  >：根据自身经营，查询企业注册时对应的标准经营范围选项
                </div>
              </div>
            </div>
            <!-- 工商注册 -->
            <div class="gszc-steps-item" style="margin-top: 46px">
              <div class="gszc-steps-item-left gszc-text">
                <div class="gszc-steps-item-title">工商注册</div>
                <div class="gszc-steps-item-content">
                  目前支持全国半自动化工商注册，（用户需自行提供注册地址），根据用户提交的工商注册材料：姓名、手机号、身份证照片（法人、股东、监事、财务）；地址材料（产权证明、租赁合同等）等相关材料，帮助用户至对应地区的工商办理企业注册
                </div>
              </div>
              <div class="gszc-steps-item-right gszc-img">
                <img src="@/assets/images/product/yszc-gszc-gszc.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="advantage">
        <div
          class="advantage-content"
          :class="{
            'pre-hide-300': true,
            'move-bottom-100-1': isShowAdvantage,
          }"
        >
          <div class="advantage-title section-title">产品优势</div>
          <div class="advantage-modules">
            <div class="advantage-modules-left">
              <div class="advantage-modules-left-title">十角兽云上注册</div>
              <div class="advantage-modules-left-list">
                <div class="advantage-modules-left-list-item">
                  <img src="@/assets/images/product/advantage-1.png" alt="" />
                  <span>轻松上手，流程简单易懂</span>
                </div>
                <div class="advantage-modules-left-list-item">
                  <img src="@/assets/images/product/advantage-2.png" alt="" />
                  <span>无隐性收费，办理进度透明</span>
                </div>
                <div class="advantage-modules-left-list-item">
                  <img src="@/assets/images/product/advantage-3.png" alt="" />
                  <span>专业顾问团队，免费咨询</span>
                </div>
                <div class="advantage-modules-left-list-item">
                  <img src="@/assets/images/product/advantage-4.png" alt="" />
                  <span>快至1天发证</span>
                </div>
              </div>
            </div>
            <div class="advantage-modules-right">
              <div class="advantage-modules-right-title">个人、代账公司</div>
              <div class="advantage-modules-right-list">
                <div class="advantage-modules-right-list-item">
                  一遍遍提交材料
                </div>
                <div class="advantage-modules-right-list-item">
                  隐性收费，流程不透明
                </div>
                <div class="advantage-modules-right-list-item">
                  回复慢专业性不强
                </div>
                <div class="advantage-modules-right-list-item">
                  拿证时间不确定，周期长
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Navgation from '@/layouts/components/Navgation'
import partnerApi from '@/api/partner.api.js'
import {pageSeoInfo} from '@/config'
export default {
  name: 'yszc',
  components: { Navgation },
  inject: ['getPosition', 'scrollTo'],
  data() {
    return {
      joinForm: {
        name: '',
        mobile: '',
        city: '',
        type: '公司注册'
      },
      serviceModules: [
        {
          title: '起名',
          label: '智能算法，秒出结果',
          content:
            '根据用户输入地区、关键字、行业名称等信息，通过大数据算法匹配，给予对应的推荐名称，帮助用户起一个好听、好记、寓意美好的企业名称',
          img: require('@/assets/images/product/yszc-qm.png'),
        },
        {
          title: '测名',
          label: '美好寓意，在线解析',
          content:
            '根据用户输入的公司名称关键字，通过算法规则，给予评分测评，同时根据关键字，进行诗词匹配，给予公司名称的相关注释。助您提前了解公司运势',
          img: require('@/assets/images/product/yszc-cm.png'),
        },
        {
          title: '核名',
          label: '直连工商，精准高效',
          content:
            '普通核名 (免费)：根据用户输入的地区、字号、行业信息等信息，通过名称匹配，给予初步的公司名称注册通过率。更有精准核名(收费)，直连工商系统超高核名效率，助您快速通过工商名称审核',
          img: require('@/assets/images/product/yszc-hm.png'),
        },
        {
          title: '经营范围',
          label: '场景化推荐经营范围',
          content:
            '经营范围查询工具，根据自身经营情况，查询公司注册时对应的标准经营范围选项。并通过场景化智能推荐，帮助您一次搞定经营范围，不遗漏',
          img: require('@/assets/images/product/yszc-jy.png'),
        },
        {
          title: '工商注册',
          label: '足不出户，快速拿证',
          content:
            '准备好工商注册必备的材料，在线提交，操作简单，为您实现足不出户，掌上办理营业执照，办理进度实施可查，下证超快',
          img: require('@/assets/images/product/yszc-gs.png'),
        },
        {
          title: '银行开户',
          label: '线上预约，专人服务',
          content:
            '线上预约银行开户，专属银行经理上门填写资料，办理开户业务。为您节约时间，提高银行开户效率，法人只需亲自前往指定柜台，一次操作即可完成银行开户',
          img: require('@/assets/images/product/yszc-yhkh.png'),
        },
      ],
      serviceHoverIndex: 0,
      isShowService: false,
      isShowGszc: false,
      isShowAdvantage: false,
      isShowXD: false,
      isShowXD2: false,
      isShowAdsorption: false,
    }
  },
  mounted() {
    this.isShowService = true
    window.addEventListener('scroll', this.onScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true)
  },
  methods: {
    onScroll() {
      const { scrollTop } = this.getPosition()
      const clientHeight = document.documentElement.clientHeight
      if (clientHeight <= 1150) {
        if (scrollTop >= 3700) {
          this.isShowAdvantage = true
        } else if (scrollTop >= 900) {
          this.isShowGszc = true
        }
      } else {
        if (scrollTop >= 3950) {
          this.isShowAdvantage = true
        } else if (scrollTop >= 1150) {
          this.isShowGszc = true
        }
      }
      if (scrollTop >= 600) {
        this.isShowXD2 = true;
        this.isShowXD = false;
        if (this.scrollTimer) {
          clearTimeout(this.scrollTimer);
          this.scrollTimer = null;
        }
        this.oldScroll = scrollTop;
        this.scrollTimer = setTimeout(() => {
          this.handleScroll(scrollTop);
        }, 700);
      } else {
        this.oldScroll = 0;
        this.newScroll = 0;
        this.isShowXD2 = true;
        this.isShowXD = false;
      }
    },
    handleScroll(scrollTop) {
      if (!this.isShowAdsorption) {
        this.isShowAdsorption = true;
      }
      this.newScroll = scrollTop;
      if (this.newScroll == this.oldScroll) {
        this.isShowXD2 = false;
        this.isShowXD = true;
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
        this.oldScroll = 0;
        this.newScroll = 0;
      }
    },
    applyJoin() {
      if (this.joinForm.name && this.joinForm.mobile && this.joinForm.city) {
        if (typeof this.joinForm.mobile !== 'number') {
          this.$message.warning('请输入正确的手机号')
          return false
        }
        if (this.joinTimer) {
          clearTimeout(this.joinTimer)
          this.joinTimer = null
        }
        this.joinTimer = setTimeout(() => {
          partnerApi
            .sendJoinMessageV2({
              ...this.joinForm,
              mobile: this.joinForm.mobile + ''
            })
            .then((res) => {
              this.$message.success('提交成功')
              this.joinForm.mobile = ''
              this.joinForm.name = ''
              this.joinForm.city = ''
            })
            .catch((err) => {
              this.$message.error('提交失败')
            })
            .finally(() => {
              clearTimeout(this.joinTimer)
              this.joinTimer = null
            })
        }, 1000)
      }
    },
  },
  metaInfo: {
    title: pageSeoInfo.product.title,
    meta: [{
      name: 'keywords',
      content: pageSeoInfo.product.keywords
    },
    {
      name: 'description',
      content: pageSeoInfo.product.description
    }]
  },
}
</script>
<style lang="less" scoped>
.product-sjsyszc-container {
  // margin-top: @navHeight;
  .section-title {
    font-size: 48px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #102a55;
  }
  .banner {
    height: 728px;
    background: linear-gradient(177deg, #023176 0%, #0a1265 18%, #060b4c 100%);
    .banner-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      .phone-img {
        width: 520px;
        height: 624px;
        align-self: flex-end;
        margin-left: 70px;
      }
      .banner-text {
        margin-top: 140px;
        position: relative;
        &-title {
          font-size: 42px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          // line-height: 95px;
          margin-bottom: 8px;
          background: linear-gradient(
            270deg,
            #51ffc4 0%,
            #24eaff 40%,
            #1464ff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        &-introduce {
          margin-top: 30px;
          margin-bottom: 70px;
          font-size: 20px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #ffffff;
        }
        &-qr {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .qr-img {
            // width: 80px;
            // height: 80px;
            width: 130px;
            height: 130px;
          }
          .qr-label {
            margin-left: 35px;
            margin-top: 18px;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.6);
            line-height: 21px;
          }
        }
      }
      .join-form-box {
        position: absolute;
        z-index: 2;
        top: 140px;
        // left: calc(50% - 186px);
        left: 0px;
        // width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .join-form {
          width: 356px;
          height: 356px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 17px;
          backdrop-filter: blur(25px);
          padding: 34px 44px;
          box-sizing: border-box;
          .el-form {
            /deep/.el-form-item {
              margin-bottom: 0px;
              .el-form-item__content {
                .el-input {
                  .el-input__inner {
                    height: 50px;
                  }
                }
              }
            }
            .el-form-item + .el-form-item {
              margin-top: 24px;
            }
          }
          .apply-btn {
            margin: 36px auto 0px;
            width: 160px;
            height: 50px;
            background: #2871ff;
            border-radius: 9px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .sjsdzjqr-video {
    position: relative;
    width: 100%;
    height: 822px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    .video-shadow{
      width: 990px;
      height: 562px;
      background: #080E57;
      opacity: 0.16;
      filter: blur(26px);
      margin: 0 auto;
      position: absolute;
      left: 50%;
      top: 130px;
      transform: translateX(-495px);
      z-index: 1;
    }
    video {
      object-fit: fill;
      // width: 100%;
      width: 1024px;
      height: 576px;
      margin: 0 auto;
      // height: 800px;
      position: relative;
      z-index: 2;
      border-radius: 10px;
    }
    .video-mask {
      width: 100%;
      height: 100%;
      background-color: rgba(6, 11, 76, 0.45);
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
    }
    .play-btn {
      width: 94px;
      height: 94px;
      position: absolute;
      z-index: 2;
      top: calc(50% - 47px);
      left: calc(50% - 47px);
      .play-img {
        width: 94px;
        height: 94px;
      }
    }
  }
  .service {
    height: 800px;
    background: #f6f9fe;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .service-title {
        margin-top: 66px;
      }
      .service-label {
        margin-top: 20px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(16, 42, 85, 0.6);
      }
      .service-module {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        &-item {
          width: 326px;
          height: 226px;
          background: #ffffff;
          border-radius: 22px;
          margin-right: 24px;
          margin-bottom: 24px;
          &:nth-of-type(3n) {
            margin-right: 0px;
          }
          &-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
              width: 56px;
              height: 56px;
            }
            &-title {
              margin-top: 10px;
              margin-bottom: 8px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: rgba(16, 42, 85, 1);
            }
            &-label {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(16, 42, 85, 0.5);
            }
          }
        }
        &-hover-item {
          background: #2871ff;
          &-content {
            padding: 36px;
            box-sizing: border-box;
            &-line {
              margin-top: 6px;
              margin-bottom: 30px;
              width: 58px;
              height: 1px;
              background: #ffffff;
            }
            &-text {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .gszc {
    height: 2800px;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .gszc-title {
        margin-top: 124px;
      }
      .gszc-steps {
        margin-top: 68px;
        &-item {
          display: flex;
          align-items: center;
          .highlight-text {
            color: rgba(40, 113, 255, 1);
          }
          .medium-text {
            font-weight: 600;
            font-family: PingFangSC-Medium, PingFang SC;
          }
          &-title {
            font-size: 40px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #102a55;
            line-height: 56px;
          }
          &-content {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(16, 42, 85, 0.8);
            line-height: 36px;
            max-width: 568px;
          }
          .qm-text {
            margin-right: 142px;
          }
          .qm-img {
            img {
              width: 315px;
              height: 405px;
            }
          }
          .cm-text {
            margin-left: 112px;
            text-align: right;
          }
          .cm-img {
            img {
              width: 360px;
              height: 384px;
            }
          }
          .hm-text {
            margin-right: 36px;
          }
          .hm-img {
            img {
              width: 420px;
              height: 478px;
            }
          }
          .jyfw-text {
            margin-left: 80px;
            text-align: right;
          }
          .jyfw-img {
            img {
              width: 408px;
              height: 440px;
            }
          }
          .gszc-text {
            margin-right: 0px;
          }
          .gszc-img {
            img {
              width: 460px;
              height: 430px;
            }
          }
        }
        .gszc-steps-qm {
          .qm-img {
            position: relative;
            .yszc-gszc-guide {
              position: absolute;
              left: -600px;
              bottom: -100px;
              img {
                width: 620px;
                height: 134px;
              }
            }
          }
        }
      }
    }
  }
  .advantage {
    height: 1050px;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .advantage-title {
        margin-top: 110px;
      }
      .advantage-modules {
        margin-top: 106px;
        display: flex;
        align-items: center;
        &-left {
          position: relative;
          z-index: 2;
          width: 512px;
          height: 598px;
          background: linear-gradient(
            313deg,
            #51e0ff 0%,
            #328bff 53%,
            #2871ff 100%
          );
          box-shadow: 22px 0px 37px 0px rgba(49, 84, 153, 0.18);
          border-radius: 58px;
          display: flex;
          flex-direction: column;
          padding-left: 106px;
          box-sizing: border-box;
          &-title {
            margin-top: 72px;
            width: 300px;
            height: 60px;
            background: #ffffff;
            border-radius: 40px;
            font-size: 26px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #3a7be7;
            text-align: center;
            line-height: 60px;
          }
          &-list {
            margin-top: 82px;
            margin-left: 24px;
            &-item {
              font-size: 20px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              display: flex;
              align-items: center;
              img {
                width: 32px;
                height: 32px;
                margin-right: 12px;
              }
            }
            .advantage-modules-left-list-item
              + .advantage-modules-left-list-item {
              margin-top: 48px;
            }
          }
        }
        &-right {
          position: relative;
          left: -52px;
          width: 528px;
          height: 484px;
          background: linear-gradient(90deg, #ffffff 0%, #e7f2ff 100%);
          box-shadow: 0px 15px 87px 0px rgba(49, 84, 153, 0.09);
          border-radius: 58px;
          display: flex;
          flex-direction: column;
          align-items: center;
          &-title {
            margin-top: 62px;
            width: 300px;
            height: 60px;
            background: linear-gradient(
              90deg,
              rgba(73, 203, 255, 0.06) 0%,
              rgba(38, 131, 248, 0.06) 100%
            );
            border-radius: 40px;
            font-size: 26px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #20427c;
            text-align: center;
            line-height: 60px;
          }
          &-list {
            margin-top: 38px;
            &-item {
              font-size: 20px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: rgba(16, 42, 85, 0.65);
            }
          }
          .advantage-modules-right-list-item
            + .advantage-modules-right-list-item {
            margin-top: 48px;
          }
        }
      }
    }
  }
  
  .pre-hide-100 {
    transform: translateY(100px);
    opacity: 0;
  }
  .pre-hide-300 {
    transform: translateY(300px);
    opacity: 0;
  }
  .move-bottom-100 {
    animation: move-bottom-100 1s 0.2s forwards ease-in-out;
  }
  .move-bottom-100-1 {
    animation: move-bottom-100-1 1s 0.2s forwards ease-in-out;
  }
  .move-bottom-300 {
    animation: move-bottom-300 1s 0.2s forwards ease-in-out;
  }
  @keyframes move-bottom-100 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-100-1 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-300 {
    0% {
      transform: translateY(300px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}
.option-cell2 {
    .name-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 22px;
      .name-txt {
        width: 146px;
        height: 51px;
        padding-top: 13px;
        box-sizing: border-box;
        flex-shrink: 1;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;
      }
      .name2 {
        margin: 0 10px;
      }
      .active {
        color: #060b4c;
        background-image: url("../assets/images/2.0/name-bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
    &.prefixed {
      animation: option-top-hide 1s forwards ease-in-out;
    }
    &.fixed {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 13;
      width: 100%;
      height: 106px;
      background: rgba(6, 11, 76, 0.5);
      transform: translateY(-300px);
      .name-cell {
        padding-top: 40px;
        margin-bottom: 22px;
      }
      .form-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
        .qm-cell,
        .hm-cell {
          height: 68px;
          padding-left: 5px;
          .form-item {
            height: 34px;
            &.form-ml {
              margin-left: 10px;
            }
            &.form-mr {
              margin-right: 10px;
            }
          }
          .submit-btn {
            margin-right: 15px;
            cursor: pointer;
          }
        }
        .cm-cell {
          height: 68px;
          // padding-left: 5px;
          .form-item {
            height: 42px;
          }
          .submit-btn {
            margin-right: 15px;
          }
        }
        .hm-cell {
          width: auto;
          .form-txt {
            margin-right: 10px;
          }
          .submit-btn {
            margin-right: 15px;
            margin-left: 15px;
          }
        }
        .submit-btn {
          height: 42px;
        }
        .hm-cell {
          display: flex;
          justify-content: center;
          align-items: center;
          // min-width: 870px;
          height: 58px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 17px;
          backdrop-filter: blur(25px);
          // padding: 0 3px;
          .form-item {
            width: 240px;
            height:34px;
            // background: #FFFFFF;
            border-radius: 9px;
            flex-shrink: 1;

            &.form-ml {
              margin-left: 12px;
            }
            &.form-mr {
              margin-right: 15px;
            }
            .el-select,
            .el-cascader {
              width: 100%;
              height: 100%;
              /deep/ .el-input {
                height: 100%;
                // background: transparent;
                .el-input__inner {
                  height: 100%;
                  // background: transparent;
                  font-size: 15px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #060b4c;
                  // border: 1px solid rgba(255, 255, 255, 0.25);
                  border-radius: 9px;
                  &::placeholder {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(16, 42, 85, 0.4);
                    // color: red;
                    letter-spacing: -1px;
                  }
                }
                .el-input__suffix {
                  // color: #fff;
                  color: rgba(26, 26, 26, 1);
                  .el-input__icon {
                    // color: #fff;
                    color: rgba(26, 26, 26, 1);
                  }
                }
              }
            }
            .el-input {
              width: 100%;
              height: 100%;
              /deep/ .el-input__inner {
                height: 100%;
                // background: transparent;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #060b4c;
                // border: 1px solid rgba(255, 255, 255, 0.25);
                border-radius: 9px;
                &::placeholder {
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(16, 42, 85, 0.4);
                }
              }
            }
          }
        }
        .hm-cell {
          width: 870px;
          padding: 0;
          margin: 0 auto;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 15px;
          box-sizing: border-box;
          .form-txt {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
    }
    .submit-btn {
      width: 160px;
      height: 50px;
      margin: 0 auto;
      background: #2871ff;
      border-radius: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .move-top {
    animation: option-top 0.5s forwards ease-in-out;
  }
  .move-hide {
    animation: option-top-hide 0.5s forwards ease-in-out;
  }
  @keyframes option-top {
  0% {
    transform: translateY(-300px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes option-top-hide {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-300px);
  }
}
</style>
